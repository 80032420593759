import React, { useState } from 'react';
import { ButtonBase, Grid, MenuItem, Theme, Typography } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from 'tss-react/mui';
import ImageNext from 'next/image';
import { HeaderMenu } from '../HeaderItems';
import ExpandIconCheckbox from '../../ExpandIconCheckbox';

interface SwitchListMarketProps {
  onClick?: (market: any) => void;
  markets: any[];
  currentMarket: any;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '6px 8px',
  },
  title: {
    paddingLeft: 5,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '120%',
    letterSpacing: '-0.02em',
  },
  button: {
    paddingRight: 8,
  },
  menuItem: {
    height: 48,
    backgroundColor: 'white',
  },
  selectedMenuTitle: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: '#4E6DF5',
  },
  menuTitle: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: theme.palette.text.primary,
  },
}));

const SwitchListMarket: React.FC<SwitchListMarketProps> = (props) => {
  const { onClick, markets, currentMarket } = props;
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const selectMarket = markets.find(({ slug }) => currentMarket === slug);

  const handleOpen = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectMarket = (market: any) => {
    onClick(market);
    handleClose();
  };

  const marketComparator = (a: any, b: any) => {
    const marketsOrder = {
      cincinnati: 1,
      detroit: 2,
      miami: 3,
      montreal: 4,
      'northern-virginia': 5,
      'silicon-valley': 5,
      'test-cincinnati': 7,
      usa: 8,
      canada: 9,
      'north-america': 10,
      international: 11,
    };
    return marketsOrder[a.slug as keyof typeof marketsOrder] - marketsOrder[b.slug as keyof typeof marketsOrder];
  };

  return (
    <>
      <Grid container wrap="nowrap">
        <ButtonBase disableRipple onClick={handleOpen} className={classes.button}>
          <ImageNext src="/static/img/LocationIcon.svg" alt="LocationIcon" width={10} height={15} />
          <Typography className={classes.title}>{selectMarket?.title}</Typography>
          <ExpandIconCheckbox checked={open} IconComponent={ArrowDropDownIcon} />
        </ButtonBase>
      </Grid>
      <HeaderMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {markets
          .sort((a: any, b: any) => marketComparator(a, b))
          .map((market) => (
            <MenuItem
              classes={{
                root: classes.root,
                dense: classes.menuItem,
              }}
              divider={
                market.slug === 'test-cincinnati' || market.slug === 'north-america' || market.slug === 'silicon-valley'
              }
              key={market.id}
              onClick={() => onSelectMarket(market)}
            >
              <Typography
                className={market.slug === selectMarket?.slug ? classes.selectedMenuTitle : classes.menuTitle}
              >
                {market.title}
              </Typography>
            </MenuItem>
          ))}
      </HeaderMenu>
    </>
  );
};

export default SwitchListMarket;
